<template>
  <div class="login-signup" style="flex-direction: column">
    <div class="login-signup-bg">
      <img src="@/assets/login-bg.svg" alt="" class="login-signup-bg-img" />
    </div>

    <div class="login-signup-form-wrapper">
      <img
        src="@/assets/logo/logo-text.svg"
        alt=""
        class="login-signup-form-logo"
      />
      <div class="login-signup-form">
        <div class="login-signup-form-btns">
          <h2 :class="{ 'login-signup-form-title': true, center: !!workspace }">
            {{ titles.title }}
          </h2>
          <p
            v-if="titles.subtitle"
            :class="{ 'login-signup-form-subtitle': true, center: !!workspace }"
            v-html="titles.subtitle"
          ></p>
          <ProviderButton
            id="signin-google"
            provider="google"
            :title="`${isLogin ? 'Log in with' : 'Continue with'} Google`"
            :class="{ 'mt-5': true, 'mb-4': true, disabled: inviteExpired }"
          />
          <ProviderButton
            id="signin-microsoft"
            provider="microsoft"
            :title="`${isLogin ? 'Log in with' : 'Continue with'} Microsoft`"
            :class="{ disabled: inviteExpired }"
          />
          <div v-if="inviteExpired" class="disabled-div"></div>
        </div>
        <div v-if="!isInvite" class="login-signup-form-redirect mt-4">
          {{
            isLogin
              ? "Don't have an account yet? "
              : 'Already have an account? '
          }}
          <router-link
            :to="{
              name: isLogin ? 'register' : 'login'
            }"
            class="login-signup-form-redirect-link"
          >
            {{ isLogin ? 'Sign up' : 'Sign in' }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProviderButton from '@/components/authentication/ProviderButton.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    ProviderButton
  },

  data() {
    return {
      errors: {
        badcode: {
          title: "We couldn't find an account with that email address",
          text: 'Contact your workspace admin to send you an invite or <a href="https://www.uman.ai/request-demo">request for a demo</a>.'
        },
        nocode: {
          title: 'Error occured during login',
          text: "The login attempt was interrupted, contact uman support if you don't know why you are receiving this error"
        }
      },
      fallbackDomain: ''
    }
  },

  computed: {
    isLogin() {
      return this.$route.name === 'login'
    },

    workspace() {
      return this.$route.query?.workspace
    },

    expires() {
      return this.$route.query?.expires?.replaceAll(' ', '+')
    },

    error() {
      return this.$route.query?.error
    },

    titles() {
      if (this.isLogin) {
        // Log in screen, assume returning user
        return {
          title: 'Welcome back!',
          subtitle: `Log back in ${
            this.hasInviteDomain
              ? 'using your <span class="bold">' +
                this.inviteDomain +
                '</span> email'
              : ''
          } to access your account.`
        }
      } else if (this.inviteExpired) {
        // Sign up screen, but with expired invite
        return {
          title: 'Your invitation has expired',
          subtitle:
            'Someone invited you to this workspace, but the invitation has expired. Contact your workspace administrator to receive a new invite.'
        }
      } else if (this.$route.query.workspace) {
        // Sign up screen, valid invite, workspace name has been passed in query
        return {
          title: `You've been invited to join the ${this.$route.query.workspace} team`,
          subtitle: this.hasInviteDomain
            ? `Log in using your <span class="bold">${this.inviteDomain}</span> email address to use the uman app.`
            : ''
        }
      } else {
        // Fallback, signup screen with no extra query params given
        return {
          title: 'Welcome to uman!',
          subtitle: this.hasInviteDomain
            ? `Log in using your <span class="bold">${this.inviteDomain}</span> email address to use the uman app.`
            : 'Get started using one of the options below'
        }
      }
    },

    inviteExpired() {
      let expiry = new Date(this.expires)
      return expiry < Date.now()
    },

    isInvite() {
      return (
        this.$route.name === 'register' && (!!this.workspace || !!this.expires)
      )
    },

    inviteDomain() {
      return this.$route.query?.domain || this.fallbackDomain
    },

    hasInviteDomain() {
      return !!this.inviteDomain
    }
  },

  mounted() {
    const redirect = this.$route.query.redirect
    if (redirect) {
      sessionStorage.setItem('redirect', redirect)
    }

    if (this.$route.query.error) {
      setTimeout(() => {
        this.$toast.danger(
          this.errors[this.$route.query.error].title,
          this.errors[this.$route.query.error].text
        )
      }, 500)
    }

    if (this.hasInviteDomain) {
      const domainFromLocalStorage = localStorage.getItem('fallbackDomain')
      if (
        !domainFromLocalStorage ||
        domainFromLocalStorage !== this.inviteDomain
      ) {
        localStorage.setItem('fallbackDomain', this.inviteDomain)
      }
    } else {
      const domainFromLocalStorage = localStorage.getItem('fallbackDomain')
      if (domainFromLocalStorage) {
        this.fallbackDomain = domainFromLocalStorage
      }
    }
  }
})
</script>

<style lang="scss">
.login-signup {
  display: grid;
  grid-template-columns: 50vw auto;
  height: 100vh;

  &-bg {
    height: 100%;
    width: 100%;
    overflow: hidden;

    &-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 0 5rem;
    gap: 1rem;

    &-wrapper {
      display: grid;
      grid-template-rows: 1fr auto 1fr;
      gap: 0.5rem;
      overflow-y: auto;
    }

    &-logo {
      height: 2rem;
      margin: 2rem 0 0 2rem;
    }

    &-title {
      font-size: 2rem;
      font-weight: 700;
    }

    &-subtitle {
      color: #60666b;
    }
  }
}

.bold {
  font-weight: 700;
}
</style>
