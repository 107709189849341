<template>
  <div :id="id" class="provider-btn">
    <div class="provider-btn-signin" @click="onClick">
      <img
        :src="require(`@/assets/${provider}/logo.png`)"
        alt=""
        class="provider-btn-logo"
      />
      <p class="provider-btn-txt">{{ title }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getAppURL } from '@/util'

export default {
  props: {
    provider: {
      type: String
    },
    title: {
      type: String
    },
    id: {
      type: String
    }
  },
  methods: {
    ...mapActions(['getOAuthState']),

    async onClick() {
      const data = await this.getOAuthState()
      const redirectURL = getAppURL(`/oauth2/callback/${this.provider}`)
      const providerUrls = {
        google: `https://accounts.google.com/o/oauth2/v2/auth?client_id=387164199820-8dv4g72djg0js6tu1qo2vqguisotvsg5.apps.googleusercontent.com&response_type=code&scope=openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&redirect_uri=${redirectURL}&state=${data.state}`,
        microsoft: `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=8a9c2818-421e-433e-a19b-c455be7a0657&response_type=code&redirect_uri=${redirectURL}&response_mode=query&scope=openid User.Read&state=${data.state}`
      }
      window.open(providerUrls[this.provider])
    }
  }
}
</script>

<style lang="scss" scoped>
.provider-btn {
  &-signin {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    border: 1px solid $grey-lighter;
    border-radius: 100000px;
    user-select: none;

    &:hover {
      cursor: pointer;
    }

    &:active {
      background: $grey-lighter;
    }
  }

  &-logo {
    height: 18px;
    width: 18px;
    margin-right: 14px;
  }

  &-txt {
    font-weight: 600;
    color: #60666b;
  }
}
</style>
