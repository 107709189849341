var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-signup",
      staticStyle: { "flex-direction": "column" }
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "login-signup-form-wrapper" }, [
        _c("img", {
          staticClass: "login-signup-form-logo",
          attrs: { src: require("@/assets/logo/logo-text.svg"), alt: "" }
        }),
        _c("div", { staticClass: "login-signup-form" }, [
          _c(
            "div",
            { staticClass: "login-signup-form-btns" },
            [
              _c(
                "h2",
                {
                  class: {
                    "login-signup-form-title": true,
                    center: !!_vm.workspace
                  }
                },
                [_vm._v(" " + _vm._s(_vm.titles.title) + " ")]
              ),
              _vm.titles.subtitle
                ? _c("p", {
                    class: {
                      "login-signup-form-subtitle": true,
                      center: !!_vm.workspace
                    },
                    domProps: { innerHTML: _vm._s(_vm.titles.subtitle) }
                  })
                : _vm._e(),
              _c("ProviderButton", {
                class: {
                  "mt-5": true,
                  "mb-4": true,
                  disabled: _vm.inviteExpired
                },
                attrs: {
                  id: "signin-google",
                  provider: "google",
                  title:
                    (_vm.isLogin ? "Log in with" : "Continue with") + " Google"
                }
              }),
              _c("ProviderButton", {
                class: { disabled: _vm.inviteExpired },
                attrs: {
                  id: "signin-microsoft",
                  provider: "microsoft",
                  title:
                    (_vm.isLogin ? "Log in with" : "Continue with") +
                    " Microsoft"
                }
              }),
              _vm.inviteExpired
                ? _c("div", { staticClass: "disabled-div" })
                : _vm._e()
            ],
            1
          ),
          !_vm.isInvite
            ? _c(
                "div",
                { staticClass: "login-signup-form-redirect mt-4" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isLogin
                          ? "Don't have an account yet? "
                          : "Already have an account? "
                      ) +
                      " "
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "login-signup-form-redirect-link",
                      attrs: {
                        to: {
                          name: _vm.isLogin ? "register" : "login"
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.isLogin ? "Sign up" : "Sign in") + " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-signup-bg" }, [
      _c("img", {
        staticClass: "login-signup-bg-img",
        attrs: { src: require("@/assets/login-bg.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }