var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "provider-btn", attrs: { id: _vm.id } }, [
    _c(
      "div",
      { staticClass: "provider-btn-signin", on: { click: _vm.onClick } },
      [
        _c("img", {
          staticClass: "provider-btn-logo",
          attrs: {
            src: require("@/assets/" + _vm.provider + "/logo.png"),
            alt: ""
          }
        }),
        _c("p", { staticClass: "provider-btn-txt" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }